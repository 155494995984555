const smoothScroll = (id: string, event?: React.MouseEvent<HTMLElement>) => {
    const element = document.getElementById(id);
    if (element) {
        element.scrollIntoView({ behavior: "smooth" });
        if (event) {
            event?.preventDefault();
        }
    }
};

export default smoothScroll;
