import React from 'react';

const useScroll = (fn: () => void, deps: React.DependencyList) => {
    React.useEffect(() => {
        const handleScroll = () => {
            fn();
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [deps, fn]);
}

export default useScroll;