import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useSpring, useSpringRef, animated } from '@react-spring/web';
import { Box, Button, Typography } from '@mui/material';
import { AppSettings } from '../../App';
import AutoAnimation from '../AutoAnimation';
import getRandom from '../../utils/getRandom';
import smoothScroll from '../../utils/smoothScroll';
import useScroll from '../../utils/useScroll';
import logoIcon from '../../assets/images/logo_icon.webp';
import logoMain from '../../assets/images/logo_main.webp';
import mainBackground from '../../assets/images/background/main.webp';
import './AppMainLogo.css';

interface AppMainLogoProps {
    settings: AppSettings;
}

export default function AppMainLogo(props: AppMainLogoProps) {
    const { t } = useTranslation();

    const bgDefaultPosition = getRandom(45, 55);
    const bgAnimation = useSpringRef();
    const [bgStyle] = useSpring(() => ({
        ref: bgAnimation,
        from: {backgroundPositionX: bgDefaultPosition + '%'},
        to: async (next, cancel) => {
            await next({backgroundPositionX: (50 + getRandom(10, 50)) + '%'});
            await next({backgroundPositionX: (50 - getRandom(10, 50)) + '%'});
            await next({backgroundPositionX: bgDefaultPosition + '%'});
        },
        loop: true,
        config: {
            tension: 1, friction: 2,
            precision: 0.0001
        }
    }));

    const [yOffset, setYOffset] = React.useState(-1);

    const logoHeight = props.settings.mainLogoHeight;
    const logoGap = props.settings.mainLogoMirrorGap;
    const mainLogoHeight = (logoHeight * 2) + (logoGap * 8);
    useScroll(() => {
        const logoTopOffset = (window.innerHeight / 2) - (mainLogoHeight / 2);
        if (window.pageYOffset >= logoTopOffset) {
            const newOffset = Math.min(mainLogoHeight, window.pageYOffset - logoTopOffset);
            if (newOffset !== yOffset) {
                setYOffset(newOffset);
            }
        } else {
            setYOffset(-1);
        }
    }, [yOffset]);

    const logoOpacity = (yOffset >= 0) ? 1.0 - (yOffset / mainLogoHeight) : 1.0;
    const logoAnimConfig = {tension: 18, friction: 9, precision: 0.001, clamp: true};
    const [logoIconStyle] = useSpring(() => ({
        from: {left: -(logoHeight * 2)},
        to: {left: 0},
        config: logoAnimConfig
    }));

    const [logoStyle] = useSpring(() => ({
        from: {top: -(logoHeight * 2), opacity: 0.0},
        to: {top: 0, opacity: 1.0},
        config: logoAnimConfig
    }));

    const [logoMirrorStyle] = useSpring(() => ({
        from: {top: (logoHeight * 2)},
        to: {top: 0},
        config: logoAnimConfig
    }));

    return <AutoAnimation api={bgAnimation} immediate={true}>
        <Box id='logo' sx={{position: 'relative', maxWidth: '100%', flex: '1 0 calc(100vh + 8px)'}}>
            <Box sx={{position: 'absolute', left: 0, right: 0, top: 0, bottom: 0}}>
                <animated.div style={{backgroundImage: 'url(' + mainBackground + ')', backgroundRepeat: 'repeat-x', backgroundSize: 'auto 150%', height: '100%', backgroundPositionY: 'center', ...bgStyle}} />
            </Box>
            <Box sx={{position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', flexFlow: 'column'}}>
                <animated.div style={{flex: 1, position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', opacity: logoStyle.opacity}}>
                    <Typography gutterBottom variant="h3" component="div" sx={{ color: '#121212', fontWeight: '200' }}>
                        <Trans i18nKey="main.logo.welcome" />
                    </Typography>
                </animated.div>
                <Box sx={{display: 'flex', flexFlow: 'row', alignItems: 'center', justifyContent: 'center', gap: 2, maxWidth: '80vw', opacity: logoOpacity}}>
                    <animated.div style={{position: 'relative', ...logoIconStyle, opacity: logoStyle.opacity}}>
                        <img src={logoIcon} style={{maxHeight: logoHeight + 'px'}} alt={t('main.logo.alt.icon')} />
                    </animated.div>
                    <Box sx={{display: 'flex', flexFlow: 'column', gap: logoGap}}>
                        <animated.div style={{position: 'relative', ...logoStyle}}>
                            <img className="AppMainLogoImage" src={logoMain} style={{maxHeight: logoHeight + 'px'}} alt={t('main.logo.alt.image')} />
                        </animated.div>

                        <animated.div style={{position: 'relative', opacity: logoStyle.opacity, ...logoMirrorStyle}}>
                            <img src={logoMain} style={{maxHeight: logoHeight + 'px', opacity: 0.1, transform: 'rotatex(180deg)'}} alt={t('main.logo.alt.image')} />
                        </animated.div>
                    </Box>
                </Box>
                <animated.div style={{flex: 1, position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', opacity: logoStyle.opacity}}>
                    <Button href='#trusted' variant="contained" onClick={(event) => smoothScroll('trusted', event)}><Trans i18nKey="main.logo.learnmore" /></Button>
                </animated.div>
            </Box>
        </Box>
    </AutoAnimation>;
}