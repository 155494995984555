import { createSvgIcon } from '@mui/material/utils';
import React from "react";

const Facebook = createSvgIcon(
    <>
        <path d="M 12.00004,2e-5 C 5.37261,2e-5 0,5.39235 0,12.04412 0,17.69256 3.87432,22.43198 9.1011,23.7335 V 15.7246 H 6.6266 v -3.68045 h 2.4745 v -1.58596 c 0,-4.0994 1.84838,-5.99954 5.85828,-5.99954 0.76025,0 2.07206,0.14967 2.60868,0.29929 v 3.3364 c -0.28325,-0.0299 -0.77516,-0.0449 -1.38637,-0.0449 -1.96761,0 -2.7279,0.74809 -2.7279,2.69306 v 1.30163 h 3.91958 l -0.67331,3.68045 H 13.45379 V 24 C 19.39553,23.2797 24,18.2019 24,12.0441 24,5.39233 18.62743,0 12.00004,0"/>
    </>,
    'Facebook',
);

export default Facebook;
