import React from 'react';
import { PropsWithChildren } from 'react';
import { Waypoint } from "react-waypoint";

export interface ImageCarouselProps {
    images: string[];
    timer?: number;
}

export default function ImageCarousel(props: PropsWithChildren<ImageCarouselProps>) {
    const [activeIndex, setActiveIndex] = React.useState(0);
    const [slideDone, setSlideDone] = React.useState(true);
    const [timeID, setTimeID] = React.useState(0);

    React.useEffect(() => {
        if (props.images.length <= 1) {
            return;
        }

        const slideNext = () => {
            setActiveIndex((val) => {
                if (val >= props.images.length - 1) {
                    return 0;
                } else {
                    return val + 1;
                }
            });
        }

        if (slideDone) {
            setSlideDone(false);
            if (timeID > 0) {
                clearTimeout(timeID);
            }

            setTimeID(window.setTimeout(() => {
                slideNext();
                setSlideDone(true);
            }, props.timer ? props.timer : 5000));
        }
    }, [slideDone, timeID, props]);

    const AutoPlayStop = () => {
        if (props.images.length > 1 && timeID > 0) {
            clearTimeout(timeID);
            setSlideDone(false);
        }
    };

    const AutoPlayStart = () => {
        if (props.images.length > 1 && !slideDone) {
            setSlideDone(true);
        }
    };

    if (props.images.length > 1) {
        return <Waypoint onEnter={() => AutoPlayStart()} onLeave={() => AutoPlayStop()}>
            <div style={{width: '100%', height: '100%', display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', alignItems: 'center', overflow: 'hidden'}}>
                {props.images.map((image, index)=> (
                    <div key={index} style={{transform: 'translateX(-' + (activeIndex * 100) + '%)', transition: 'transform 1s ease', minWidth: '100%', height: '100%', backgroundImage: 'url(' + image + ')', backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}} />
                ))}
            </div>
        </Waypoint>;
    } else {
        return <div style={{minWidth: '100%', height: '100%', backgroundImage: 'url(' + props.images[0] + ')', backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}} />;
    }
}
