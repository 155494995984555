import * as React from 'react';

type JSONValue =
    | string
    | number
    | boolean
    | JSONObject
    | JSONArray;

interface JSONObject {
    [x: string]: JSONValue;
}

interface JSONArray extends Array<JSONValue> { }

const useLocalStorage = (storageKey: string, fallbackState: JSONValue) => {
    const [value, setValue] = React.useState(() => {
        var data = localStorage.getItem(storageKey);
        return data ? JSON.parse(data) : fallbackState
    });

    React.useEffect(() => {
        localStorage.setItem(storageKey, JSON.stringify(value));
    }, [value, storageKey]);

    return [value, setValue];
};

export default useLocalStorage;