import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Box } from '@mui/material';
import { Card, CardActions, CardContent } from '@mui/material';
import Typography from '@mui/material/Typography';
import AppMainCard from './AppMainCard';
import { AppMainCardProps } from './AppMainCard';

export default function AppMainPromos(props: AppMainCardProps) {
    const { t } = useTranslation();

    return <AppMainCard {...props} color='primary' header='main.promos.header'>
        <Box sx={{display: 'flex', flexFlow: 'row wrap', alignItems: 'stretch', justifyContent: 'space-between', gap: 2}}>
            <Card variant="outlined" elevation={0} sx={{ display: 'flex', flexFlow: 'column', flex: '1 0 345px' }}>
                <CardContent sx={{flexGrow: 1}}>
                    <Typography gutterBottom variant="h5" sx={{ fontWeight: '200', pb: 2 }}>
                        <Trans i18nKey='main.promos.consultation.header' />
                    </Typography>
                    <Typography variant="body1" component='span' sx={{ color: 'text.secondary' }}>
                        <Trans i18nKey='main.promos.consultation.content' />
                    </Typography>
                </CardContent>
                <CardActions sx={{justifyContent: 'center'}}>
                    <Button variant="contained"><Trans i18nKey="main.promos.consultation.learnmore" /></Button>
                </CardActions>
            </Card>
            <Card variant="outlined" elevation={0} sx={{ display: 'flex', flexFlow: 'column', flex: '1 0 345px' }}>
                <CardContent sx={{flexGrow: 1}}>
                    <Typography gutterBottom variant="h5" sx={{ fontWeight: '200', pb: 2 }}>
                        <Trans i18nKey='main.promos.offourservices.header' />
                    </Typography>
                    <Typography variant="body1" component='span' sx={{ color: 'text.secondary' }}>
                        <Trans i18nKey='main.promos.offourservices.content' />
                    </Typography>
                </CardContent>
                <CardActions sx={{justifyContent: 'center'}}>
                    <Button variant="contained" href={t("link.email_subject", {subject: t("main.promos.offourservices.subject")})} target="_blank"><Trans i18nKey="main.promos.offourservices.booknow" /></Button>
                </CardActions>
            </Card>
        </Box>
    </AppMainCard>;
}