import React from 'react';
import { PropsWithChildren } from 'react';
import { Waypoint } from "react-waypoint";
import { SpringRef } from '@react-spring/web';

interface AutoAnimationProps {
    api: SpringRef;
    immediate?: boolean;
}

export default function AutoAnimation(props: PropsWithChildren<AutoAnimationProps>) {
    let started = React.useRef(false);
    React.useEffect(() => {
        if (props.immediate) {
            props.api.start();
            started.current = true;
        }
    }, [props]);

    const resumeAnimation = () => {
        if (started.current) {
            props.api.resume();
        } else {
            props.api.start();
            started.current = true;
        }
    };

    const pauseAnimation = () => {
        if (started.current) {
            props.api.pause();
        }
    };

    return <Waypoint onEnter={resumeAnimation} onLeave={pauseAnimation}>
        {props.children && 
            props.children
        }
    </Waypoint>;
}
