import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Box } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Card, CardActions, CardContent } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import AppMainCard from './AppMainCard';
import { AppMainCardProps } from './AppMainCard';

export interface LearnMoreDialogProps {
    open: boolean;
    id: string;
    onClose: () => void;
}

function LearnMoreDialog(props: LearnMoreDialogProps) {
    const { t } = useTranslation();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClose = () => {
        props.onClose();
    };

    return (
        <Dialog onClose={handleClose} fullScreen={fullScreen} open={props.open}>
            <DialogTitle>{props.open ? t(props.id + ".header")  : "" }</DialogTitle>
            <DialogContent>
                <Typography variant="body1" component='span' style={{whiteSpace: 'pre-line'}}>
                    <Trans i18nKey={props.open ? t(props.id + ".morecontent") : ""}
                        components={{ subheader: <Typography variant="h6" sx={{ fontWeight: '200', pb: 2 }} /> }} />
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>{t("dialog.close")}</Button>
            </DialogActions>
        </Dialog>
    );
}

export default function AppMainOurServices(props: AppMainCardProps) {
    const { t } = useTranslation();

    const [learnMoreID, setLearnMoreID] = React.useState("");
    const handleLearnMoreClick = (id: string) => {
        setLearnMoreID(id);
    };

    const handleLearnMoreClose = () => {
        setLearnMoreID("");
    };

    return <AppMainCard {...props} color='secondary' header='main.ourservices.header'>
        <Box sx={{display: 'flex', flexFlow: 'row wrap', alignItems: 'stretch', justifyContent: 'space-between', gap: 2}}>
            <Card elevation={2} sx={{ display: 'flex', flexFlow: 'column', flex: '1 0 345px' }}>
                <CardContent sx={{flexGrow: 1}}>
                    <Typography gutterBottom variant="h5" sx={{ fontWeight: '200', pb: 2 }}>
                        <Trans i18nKey='main.ourservices.troubleshooting.header' />
                    </Typography>
                    <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                        <Trans i18nKey='main.ourservices.troubleshooting.content' />
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button size="medium" onClick={() => { handleLearnMoreClick("main.ourservices.troubleshooting") }}><Trans i18nKey="main.ourservices.learnmore" /></Button>
                    <Button size="medium" variant="contained" href={t("link.email_subject", {subject: t("main.ourservices.troubleshooting.header")})} target="_blank"><Trans i18nKey="main.ourservices.requestquote" /></Button>
                </CardActions>
            </Card>
            <Card elevation={2} sx={{ display: 'flex', flexFlow: 'column', flex: '1 0 345px' }}>
                <CardContent sx={{flexGrow: 1}}>
                    <Typography gutterBottom variant="h5" sx={{ fontWeight: '200', pb: 2 }}>
                        <Trans i18nKey='main.ourservices.digitalstrategy.header' />
                    </Typography>
                    <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                        <Trans i18nKey='main.ourservices.digitalstrategy.content' />
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button size="medium" onClick={() => {handleLearnMoreClick("main.ourservices.digitalstrategy")}}><Trans i18nKey="main.ourservices.learnmore" /></Button>
                    <Button size="medium" variant="contained" href={t("link.email_subject", {subject: t("main.ourservices.digitalstrategy.header")})} target="_blank"><Trans i18nKey="main.ourservices.requestquote" /></Button>
                </CardActions>
            </Card>
            <Card elevation={2} sx={{ display: 'flex', flexFlow: 'column', flex: '1 0 345px' }}>
                <CardContent sx={{flexGrow: 1}}>
                    <Typography gutterBottom variant="h5" sx={{ fontWeight: '200', pb: 2 }}>
                        <Trans i18nKey='main.ourservices.itconsultation.header' />
                    </Typography>
                    <Typography variant="body1" component='span' sx={{ color: 'text.secondary' }}>
                        <Trans i18nKey='main.ourservices.itconsultation.content' />
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button size="medium" onClick={() => {handleLearnMoreClick("main.ourservices.itconsultation")}}><Trans i18nKey="main.ourservices.learnmore" /></Button>
                    <Button size="medium" variant="contained" href={t("link.email_subject", {subject: t("main.ourservices.itconsultation.header")})} target="_blank"><Trans i18nKey="main.ourservices.requestquote" /></Button>
                </CardActions>
            </Card>
            <LearnMoreDialog
                open={learnMoreID !== ""}
                id={learnMoreID}
                onClose={handleLearnMoreClose} />
        </Box>
    </AppMainCard>;
}