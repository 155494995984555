import React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Theme } from '@mui/material/styles/createTheme';
import CssBaseline from '@mui/material/CssBaseline';
import { grey } from '@mui/material/colors';
import { PaletteMode } from '@mui/material';
import AppHeader from './components/AppHeader';
import AppMain from './components/AppMain';
import smoothScroll from './utils/smoothScroll';
import useLocalStorage from './utils/useLocalStorage'
import './App.css';

declare module '@mui/material/styles' {
    interface Theme {
        background: {
            header: string;
        };
    }
    interface ThemeOptions {
        background?: {
            header?: string;
        };
    }
}

const getDesignTokens = (mode: PaletteMode) => ({
    palette: {
        mode,
        ...(mode === 'light' && {
            primary: {
                main: grey.A100
            },
            secondary: {
                main: grey[900]
            },
            text: {
                primary: grey[900],
                secondary: grey[700],
                disabled: grey[600],
            },
            background: {
                default: grey.A100,
                paper: grey.A100,
                header: grey.A200
            },
        }),
        ...(mode === 'dark' && {
            primary: {
                main: grey.A100
            },
            secondary: {
                main: grey[900]
            },
            text: {
                primary: grey[50],
                secondary: grey[300],
                disabled: grey[400]
            },
            background: {
                default: '#121212',
                paper: '#121212',
                header: '#2e4258c0'
            },
        }),
    },
});

export type AppSettings = {
    theme: Theme;
    headerHeight: number;
    mainLogoHeight: number;
    mainLogoMirrorGap: number;
};

export default function App() {
    // Ensure i18next is loaded
    useTranslation();

    const [themeMode, setTheme] = useLocalStorage('theme', 'system');
    const systemDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const toggleColorMode = () => {
        setTheme((prevMode: PaletteMode) =>
            prevMode === 'light' ? 'dark' : 'light',
        );
    };

    const darkMode = (themeMode === 'system') ? systemDarkMode : (themeMode === 'dark');
    const theme = createTheme(getDesignTokens(darkMode ? 'dark' : 'light'))

    const settings: AppSettings = {
        theme: theme,
        headerHeight: 64,
        mainLogoHeight: 64,
        mainLogoMirrorGap: 4
    };

    useEffect(() => {
        const hash = window.location.hash.substr(1);
        if (hash !== '') {
            smoothScroll(hash);
        }
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <AppHeader settings={settings} toggleColorMode={toggleColorMode} />
            <AppMain settings={settings} />
        </ThemeProvider>
    );
}
