import React from 'react';
import { useSpring, animated } from '@react-spring/web';
import { AppBar, Box } from '@mui/material';
import { AppSettings } from '../App';
import useScroll from '../utils/useScroll';
import logoMain from '../assets/images/logo_main.webp';
import logoIcon from '../assets/images/logo_icon.webp';
import './AppHeader.css';

interface AppHeaderProps {
    settings: AppSettings;
    toggleColorMode: () => void;
}

export default function AppHeader(props: AppHeaderProps) {
    const [yOffset, setYOffset] = React.useState(-1);

    const mainLogoHeight = (props.settings.mainLogoHeight * 2) + (props.settings.mainLogoMirrorGap * 8);
    useScroll(() => {
        const logoTopOffset = (window.innerHeight / 2) - (mainLogoHeight / 2);
        if (window.pageYOffset >= logoTopOffset) {
            const newOffset = Math.min(mainLogoHeight, window.pageYOffset - logoTopOffset);
            if (newOffset !== yOffset) {
                setYOffset(newOffset);
            }
        } else {
            setYOffset(-1);
        }
    }, [yOffset]);

    const ratio = yOffset / mainLogoHeight;
    const bgStyle = useSpring({
        to: {top: (yOffset >= 0) ? -(props.settings.headerHeight - (ratio * props.settings.headerHeight)) : -props.settings.headerHeight}
    });

    return (
        <animated.div style={{width: '100%', position: 'fixed', zIndex: 1, ...bgStyle}}>
            <AppBar
                position="relative"
                color="primary"
                elevation={(yOffset >= 0) ? 3 : 0}
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    bgcolor: '#afc1d0ff',
                    backgroundImage: 'none',
                    height: props.settings.headerHeight + 'px',
                    minWidth: '80vw',
                }}
                >
                <Box sx={{width: '100%', height: '100%', display: 'flex', flexFlow: 'row', alignItems: 'center', justifyContent: 'center', gap: 2, py: 1, zIndex: 1}}>
                    <img src={logoIcon} alt="icon" style={{height: '40px'}} />
                    <img src={logoMain} className="AppHeader-logo" alt="logo" style={{height: '40px'}} />
                </Box>
            </AppBar>
        </animated.div>
    );
}
