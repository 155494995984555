import React from 'react';
import { Box } from '@mui/material';
import { AppSettings } from '../App';
import { AppMainLogo, AppMainCard, AppMainOurServices, AppMainPromos, AppMainContact } from './main';
import backgroundFerry1 from '../assets/images/background/ferry_1.webp';
import backgroundAkersberga1 from '../assets/images/background/akersberga_1.webp';
import backgroundAkersberga2 from '../assets/images/background/akersberga_2.webp';
import backgroundAkersberga3 from '../assets/images/background/akersberga_3.webp';
import backgroundAkersberga4 from '../assets/images/background/akersberga_4.webp';
import backgroundNorrtalje1 from '../assets/images/background/norrtalje_1.webp';
import backgroundNorrtalje2 from '../assets/images/background/norrtalje_2.webp';
import backgroundLjustero1 from '../assets/images/background/ljustero_1.webp';
import backgroundFlowers1 from '../assets/images/background/flowers_1.webp';
import backgroundFlowers2 from '../assets/images/background/flowers_2.webp';
import backgroundFlowers3 from '../assets/images/background/flowers_3.webp';
import './AppHeader.css';

interface AppMainProps {
    settings: AppSettings;
}

export default function AppMain(props: AppMainProps) {
    return <Box className="AppMain" sx={{display: 'flex', flexFlow: 'column'}}>
        <AppMainLogo settings={props.settings} />
        <AppMainCard settings={props.settings} id='trusted' header='main.trusted.header' bodyContent='main.trusted.content' cardColor='#1c3f60c0' background={[backgroundFerry1]} />
        <AppMainCard settings={props.settings} id='empowering' header='main.empowering.header' bodyContent='main.empowering.content' cardColor='#0d2538c0' background={[backgroundAkersberga3]} />
        <AppMainCard settings={props.settings} id='grow' header='main.grow.header' bodyContent='main.grow.content' cardColor='#1c3f60c0' background={[backgroundFlowers1]} />
        <AppMainCard settings={props.settings} id='technical' header='main.technical.header' bodyContent='main.technical.content' cardColor='#2e4258c0' background={[backgroundNorrtalje1]} />
        <AppMainCard settings={props.settings} id='towns' header='main.towns.header' subheader='main.towns.subheader' bodyContent='main.towns.content' cardColor='#1c3f60c0' background={[backgroundLjustero1, backgroundAkersberga2, backgroundNorrtalje2]} backgroundTimer={5000}/>
        <AppMainCard settings={props.settings} id='itservices' header='main.whatareitservices.header' bodyContent='main.whatareitservices.content' cardColor='#2e4258c0' background={[backgroundAkersberga4]} />
        <AppMainOurServices settings={props.settings} id='ourservices' cardColor='#afc1d0c0' background={[backgroundFlowers3]} />
        <AppMainPromos settings={props.settings} id='promos' cardColor='#0d2538c0' background={[backgroundFlowers2]} />
        <AppMainContact settings={props.settings} id='contact' cardColor='#2e4258c0' />
    </Box>;
}
