import React from 'react';
import { PropsWithChildren } from 'react';
import { Trans } from 'react-i18next';
import { useSpring, useSpringRef, animated } from '@react-spring/web';
import { Box, Paper, Typography } from '@mui/material';
import AutoAnimation from '../AutoAnimation';
import ImageCarousel from '../ImageCarousel';
import { AppSettings } from '../../App';

export interface AppMainCardProps {
    settings: AppSettings;
    id: string;
    noAnimate?: boolean;
    color?: string;
    cardColor: string;
    background?: string[];
    backgroundTimer?: number;
    fill?: boolean;
    header?: string;
    subheader?: string;
    bodyContent?: string;
}

export default function AppMainCard(props: PropsWithChildren<AppMainCardProps>) {
    const swipeAnimation = useSpringRef();
    const swipeStyle = useSpring({
        ref: swipeAnimation,
        from: {x: -250, opacity: 0},
        to: {x: 0, opacity: 1},
        clamp: true
    });

    const bgAnimation = useSpringRef();
    const bgStyle = useSpring({
        ref: bgAnimation,
        from: {left: '0px'},
        to: {left: '-125px'}
    });

    let background = <></>
    if (props.background && props.background.length > 0) {
        background = <div style={{position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, overflow: 'hidden'}}>
            <AutoAnimation api={bgAnimation}>
                <animated.div style={{position: 'relative', width: 'calc(100% + 250px)', height: '100%', ...bgStyle}}>
                    <ImageCarousel images={props.background} timer={props.backgroundTimer} />
                </animated.div>
            </AutoAnimation>
        </div>;
    }

    return <AutoAnimation api={swipeAnimation}>
        <Box id={props.id && props.id} style={{position: 'relative', scrollMarginTop: props.settings.headerHeight + 'px'}}>
            {background}
            <animated.div {...(!props.noAnimate && {style: swipeStyle})}>
                <Paper elevation={1} sx={{...(props.fill ? {m: 0, p: 2} : {m: 1, py: 10, px: 4}), position: 'relative', maxWidth: '100%', backgroundColor: props.cardColor }}>
                    {props.header && 
                        <Typography color={props.color ? props.color : "primary"} gutterBottom variant="h4" sx={{ fontWeight: '200', pb: (props.subheader ? 0 : 2) }}>
                            <Trans i18nKey={props.header} />
                        </Typography>
                    }
                    {props.subheader && 
                        <Typography color={props.color ? props.color : "primary"} gutterBottom variant="h5" sx={{ fontWeight: '200', pt: 1, pb: 2 }}>
                            <Trans i18nKey={props.subheader} />
                        </Typography>
                    }
                    {props.bodyContent && 
                        <Typography color={props.color ? props.color : "primary"} gutterBottom component='span' variant="body1" sx={{ fontWeight: '200' }}>
                            <Trans i18nKey={props.bodyContent} components={{ subheader: <Typography variant="h5" sx={{ fontWeight: '200', pb: 2 }} /> }} />
                        </Typography>
                    }
                    {props.children && 
                        props.children
                    }
                </Paper>
            </animated.div>
        </Box>
    </AutoAnimation>;
}