import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Box from '@mui/material/Box';
import { IconButton } from '@mui/material';
import { Card, CardContent } from '@mui/material';
import { Avatar, List, ListItem, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import PlaceIcon from '@mui/icons-material/Place';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '../../icons/Facebook';
import InstagramIcon from '../../icons/Instagram';
import AppMainCard from './AppMainCard';
import { AppMainCardProps } from './AppMainCard';


interface AppHeaderIcon {
    name: string;
    icon: typeof SvgIcon;
    size?: number;
    href?: string;
    props?: object;
}

const renderHeaderIcon = (icon: AppHeaderIcon) => {
    return (
        <IconButton key={icon.name} aria-label={icon.name} sx={{ml: 1, mr: 1}} {...icon.href ? {href: icon.href, target: '_blank'} : {}} {...icon.props}>
            <icon.icon sx={{fontSize: (icon.size ? icon.size : 48), color: 'text.secondary'}} />
        </IconButton>
    );
};

const renderHeaderIcons = (icons: AppHeaderIcon[]) => {
    return icons.map(icon => {
        return renderHeaderIcon(icon);
    });
};

export default function AppMainContact(props: AppMainCardProps) {
    const { t } = useTranslation();

    let followIcons: AppHeaderIcon[] = [
        {name: "facebook", icon: FacebookIcon, href: 'https://www.facebook.com/CedomerSweden/'},
        {name: "instagram", icon: InstagramIcon, href: 'https://www.instagram.com/CedomerSweden/'}
    ];

    return <AppMainCard {...props} noAnimate fill color='primary'>
        <Box sx={{display: 'flex', flexFlow: 'row wrap', alignItems: 'stretch', justifyContent: 'space-between', gap: 2}}>
            <Card elevation={2} sx={{ display: 'flex', flexFlow: 'column', flex: '1 0', minWidth: 345 }}>
                <CardContent sx={{flexGrow: 1}}>
                    <Typography gutterBottom variant="h5" sx={{ fontWeight: '200' }}>
                        <Trans i18nKey='main.contact.contactus.header' />
                    </Typography>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton href={t('link.address')} target='_blank'>
                                <ListItemAvatar>
                                    <Avatar>
                                        <PlaceIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText>
                                    <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                                        <Trans i18nKey='main.contact.contactus.address' />
                                    </Typography>
                                </ListItemText>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton href={t('link.phone')} target='_blank'>
                                <ListItemAvatar>
                                    <Avatar>
                                        <PhoneIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText>
                                    <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                                        <Trans i18nKey='main.contact.contactus.phone' />
                                    </Typography>
                                </ListItemText>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton href={t('link.email')} target='_blank'>
                                <ListItemAvatar>
                                    <Avatar>
                                        <EmailIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText>
                                    <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                                        <Trans i18nKey='main.contact.contactus.email' />
                                    </Typography>
                                </ListItemText>
                            </ListItemButton>
                        </ListItem>
                    </List>
                </CardContent>
            </Card>
            <Card elevation={2} sx={{ display: 'flex', flexFlow: 'column', flex: '1 0', minWidth: 345 }}>
                <CardContent sx={{flexGrow: 1}}>
                    <Typography gutterBottom variant="h5" sx={{ fontWeight: '200', pb: 2 }}>
                        <Trans i18nKey='main.contact.officehours.header' />
                    </Typography>
                    <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                        <Trans i18nKey='main.contact.officehours.content' />
                    </Typography>
                </CardContent>
            </Card>
            <Card elevation={2} sx={{ display: 'flex', flexFlow: 'column', flex: '1 0', minWidth: 345 }}>
                <CardContent sx={{flexGrow: 1}}>
                    <Typography gutterBottom variant="h5" sx={{ fontWeight: '200', pb: 2 }}>
                        <Trans i18nKey='main.contact.followus.header' />
                    </Typography>
                    {renderHeaderIcons(followIcons)}
                </CardContent>
            </Card>
        </Box>
    </AppMainCard>;
}